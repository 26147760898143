import React from 'react';
import { connect } from 'react-redux';
import * as sessionAPI from '../api/SessionAPI';
import * as customerAPI from '../api/CustomerAPI';
import LoginComponent from '../components/login/LoginComponent';
import Modal from '../components/common/ModalComponent';
import AccountRegistrationForm from '../components/form/AccountRegistration';
import ForgotPasswordForm from '../components/form/ForgotPasswordForm';
import { Flex, Text } from '@chakra-ui/react';
import { ExternalContainer } from './ExternalContainer';
import withNavigation from "../withNavigation";
import {Navigate} from "react-router-dom";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: { heading: null, body: null },
      showPersist: false,
      persistLogin: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.showCreateFlowMSPAccountModal = this.showCreateFlowMSPAccountModal.bind(this);
  }

  componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);
    const returnTo = resolveReturnTo(query.get("returnTo"));
    const partnerParam = query.get('partner');
    if (partnerParam) {
      this.setState({ ...this.state, returnTo, persistLogin: true, showPersist: true });
    } else {
      this.setState({ ...this.state, returnTo });
    }
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  showCreateFlowMSPAccountModal() {
    this.setState({
      modal: {
        heading: 'Create new FlowMSP account',
        body: <AccountRegistrationForm toggleModal={this.toggleModal}/>
      }
    });
    this.toggleModal();
  }

  //Forgot Password
  handleForgotPassword = () => {
    this.setState({
      modal: {
        heading: 'Forgot Password',
        body: <ForgotPasswordForm toggleModal={this.toggleModal} />
      }
    });
    this.toggleModal();
  };

  render() {
    const loginComponentProps = {
      handleCreateAccountClick: this.showCreateFlowMSPAccountModal,
      errorMessage: this.props.errorMessage,
      handleForgotPassword: this.handleForgotPassword,
      persistLogin: this.state.persistLogin,
      showPersist: this.state.showPersist
    };

    // Logged in? Move to dashboard page
    if (this.props.isLoggedIn) {
      return <Navigate to={this.state.returnTo ?? "/"} />;
    }

    return (
      <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <LoginComponent {...loginComponentProps} />
        <Text fontSize="0.75rem" color="gray.400" mt="0.5rem" textAlign="center" title={process.env.NODE_ENV}>
          v{process.env.REACT_APP_VERSION}
        </Text>
        <Modal showModal={this.state.showModal} toggleModal={this.toggleModal} modal={this.state.modal} />
      </Flex>
      </ExternalContainer>
    );
  }
}

/**
 * @param {string|null|undefined} returnTo
 */
function resolveReturnTo(returnTo) {
  if (!returnTo || typeof returnTo !== "string") {
    return "/";
  }

  let resolvedReturnTo = returnTo.trim();

  // Ensure the return path is relative (no protocol) and does not include a login page
  if (/[:]/.test(resolvedReturnTo) || /(^|\/)login(\/|\?|$)/g.test(resolvedReturnTo)) {
    resolvedReturnTo = "/";
  }

  return resolvedReturnTo;
}

const mapStateToProps = function(store) {
  return {
    errorMessage: store.session.errorMessage,
    isLoggedIn: store.session.isLoggedIn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (credentials, successCallback, errorCallback) => sessionAPI.loginUser(dispatch, credentials, successCallback, errorCallback),
    createCustomer: (form, successCallback, errorCallback) => customerAPI.createCustomer(form, successCallback, errorCallback)
  };
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));
