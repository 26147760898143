import React from 'react';
import {Route, Routes} from 'react-router-dom';
import MapContainer from './containers/MapContainer';
import LocationContainer from './containers/LocationContainer';
import LoginContainer from './containers/LoginContainer';
import AutoLoginContainer from './containers/AutoLoginContainer';
import PasswordResetContainer from './containers/PasswordResetContainer';
import OidcRootContainer from "./containers/OidcRootContainer";
import DepartmentLoginContainer from "./containers/DepartmentLoginContainer";
import OidcSignUpContainer from "./containers/OidcSignUpContainer"
import OidcCallbackContainer from "./containers/OidcCallbackContainer";
import OidcLinkUserContainer from "./containers/OidcLinkUserContainer";
import UploadDataContainer from './containers/UploadDataContainer';
// import ReportPanelContainer from './containers/ReportPanelContainer';
import DataSharingContainer from './containers/DataSharingContainer';
import SignUpSecondaryContainer from './containers/SignUpSecondaryContainer';
import CreateDepartmentContainer from './containers/CreateDepartmentContainer';
import { PreplansContainer } from './containers/dashboard/PrePlanTable';
import { ProfileContainer } from './containers/dashboard/ProfileContainer';
import AccountInfoComponent from './components/AccountInfo/AccountInfoComponent';
import { PrePlanExport } from './components/pdf/Preplan';
import {RequireAuth} from "./components/RequireAuth";
import App from "./annotator-demo/App";

export default (
    <Routes>
        <Route exact path="/login" Component={LoginContainer} />
        <Route exact path="/login/:slug" Component={DepartmentLoginContainer} />
        <Route exact path="/loginAuto" Component={AutoLoginContainer} />
        <Route exact path="/passwordResetForm" Component={PasswordResetContainer} />
        <Route exact path="/signUpForm" Component={SignUpSecondaryContainer} />
        <Route exact path="/createDepartment" Component={CreateDepartmentContainer} />

        <Route exact path="/oidc-callback" Component={OidcCallbackContainer} />
        <Route exact path="/oidc" Component={OidcRootContainer} />
        <Route exact path="/oidc/:slug/login" element={<DepartmentLoginContainer requireOidc />} />
        <Route exact path="/oidc/:slug/signUpForm" Component={OidcSignUpContainer} />
        <Route exact path="/oidc/:slug/linkUserForm" Component={OidcLinkUserContainer} />

        <Route exact path="/" element={
            <RequireAuth>
                <MapContainer />
            </RequireAuth>
        } />
        <Route exact path="/locations/:id" element={
            <RequireAuth>
                <LocationContainer />
            </RequireAuth>
        } />

        <Route exact path="/my-profile" element={
            <RequireAuth>
                <ProfileContainer />
            </RequireAuth>
        } />
        <Route exact path="/account-info" element={
            <RequireAuth role="ADMIN">
                <AccountInfoComponent />
            </RequireAuth>
        } />
        <Route exact path="/upload-data" element={
            <RequireAuth role="ADMIN">
                <UploadDataContainer />
            </RequireAuth>
        } />
        {/*<Route path="/report-panel" element={*/}
        {/*    <RequireAuth role="ADMIN">*/}
        {/*        <ReportPanelContainer />*/}
        {/*    </RequireAuth>*/}
        {/*} />*/}
        <Route exact path="/data-sharing" element={
            <RequireAuth role="ADMIN">
                <DataSharingContainer />
            </RequireAuth>
        } />
        <Route exact path="/dashboard" element={
            <RequireAuth>
                <PreplansContainer />
            </RequireAuth>
        } />
        <Route exact path="/export/:id" element={
            <RequireAuth>
                <PrePlanExport />
            </RequireAuth>
        } />

        {process.env.NODE_ENV === 'development' && (
            <Route exact path="/tests/annotator" element={
                <RequireAuth role="ADMIN">
                    <App />
                </RequireAuth>
            } />
        )}

    </Routes>
);
