import React, { useState } from 'react';
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  HStack,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
//import { useNavigate } from 'react-router';
import * as sessionAPI from '../../api/SessionAPI';
import { LinkButton, SubmitButton } from '../form/Button';
import LoginBoxComponent from "./LoginBoxComponent";


// class LoginComponent extends React.Component {
const LoginComponent = (props) => {
  const dispatch = useDispatch();
  //const navigate = useNavigate();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting
    },
  } = useForm();

  const [state, setState] = useState({
    credentials: {
      email: '',
      password: '',
    },
    isLoggingIn: false,
    showPersist: props.showPersist,
    persistLogin: props.persistLogin
  });
  const onSave = (values) => {

    return new Promise(resolve => sessionAPI.loginUser(
      dispatch,
      { ...values, persistLogin: state.persistLogin },
      () => {
        resolve();
      }, // void onSuccess,
      (message) => {
        toast({
          title: 'Login failed',
          position: 'top',
          description: message,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        resolve();
      })
    );
  };

  return (
    <LoginBoxComponent title="Log In">
      <Stack spacing="1rem">
        <form method="post" onSubmit={handleSubmit(onSave)}>
          <Stack spacing="1rem" maxW={'xl'}>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Enter email"
                {...register('email', {
                  required: 'Email required'
                })}
              />
            </FormControl>
            <FormControl id="password">
              <Flex justifyContent="space-between" alignItems="baseline">
                <FormLabel>Password</FormLabel>
                <LinkButton onClick={props.handleForgotPassword}>Forgot Password</LinkButton>
              </Flex>
              <Input
                id="password"
                type="password"
                {...register('password', {
                  required: 'Password required'
                })}
              />
              {state.showPersist ?
                <Flex justifyContent="start" marginTop="0.5rem">
                  <Checkbox defaultChecked={state.persistLogin} onChange={(e) => setState({ ...state, persistLogin: e.target.checked })}>
                    Remember me
                  </Checkbox>
              </Flex>
              : null}
            </FormControl>
          </Stack>
          <Stack paddingTop="1.5rem" spacing="0.5rem">
            <SubmitButton isLoading={isSubmitting}>Sign In </SubmitButton>
            <FormControl isInvalid={errors.authentication}>
            {errors.authentication && (
              <FormErrorMessage>{errors.authentication.message}</FormErrorMessage>
            )}
            </FormControl>
          </Stack>
        </form>
        <Divider />
        {/* <HStack justifyContent="center">
          <p>ImageTrend Elite account?</p>
          <LinkButton onClick={() => { navigate("/oidc"); }} fontSize="md">Elite Sign In</LinkButton>
        </HStack> */}
        <HStack justifyContent="center">
          <p>New to FlowMSP?</p>
          <LinkButton onClick={() => { window.location.href = "https://flowmsp.com/request-a-demo/"; }} fontSize="md">Request a Demo</LinkButton>
        </HStack>
      </Stack>
    </LoginBoxComponent>
  );
};


export default LoginComponent;
