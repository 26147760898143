import { Center, Flex, Link } from "@chakra-ui/react";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { loginUserByIdToken } from "../api/OidcApi";
import ErrorComponent from "../components/common/ErrorComponent";
import SpinnerComponent from "../components/common/SpinnerComponent";
import LoginBoxComponent from "../components/login/LoginBoxComponent";
import { base64url, clearOidcSessionStorage, getOidcSessionStorage, OAuthError, parseOidcState } from "../helpers/Oidc";
import withNavigation from "../withNavigation";
import { ExternalContainer } from "./ExternalContainer";

function OidcCallbackContainer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  /** @type {URLSearchParams} */
  let query;
  try {
    query = new URLSearchParams(props.location?.search);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Invalid or missing query string", err);
    query = new URLSearchParams();
  }
  const code = query.get("code");
  const state = query.get("state");
  const errorDescription = query.get("error_description");
  const error = query.get("error");

  React.useEffect(() => {
    setErrorMessage(undefined);
    processAuthorizationCodeAsync(dispatch, code, state, errorDescription, error)
      .then(({ returnTo }) => {
        navigate(returnTo ?? "/");
      })
      .catch((err) => {
        // Special case: if the authentication was successful but the user has not been signed up yet,
        // we may need to complete the sign up process by asking for the user's email address.
        if (OAuthError.isOAuthError(err) && err.error === "email_required" && err.data?.id_token && err.data?.slug) {
          navigate(`/oidc/${encodeURIComponent(err.data.slug)}/signUpForm?id_token=${encodeURIComponent(err.data.id_token)}`);
          return;
        }

        if (OAuthError.isOAuthError(err) && err.error !== "server_error") {
          setErrorMessage(err.message);
        } else {
          setErrorMessage(errorDescription ?? "An error occurred");
        }
      });
  }, [code, state, errorDescription, error, navigate, dispatch]);

  const loginPath = React.useMemo(() => {
    let slug;
    let returnTo;
    try {
      const parsedState = parseOidcState(state);
      slug = parsedState.slug;
      returnTo = parsedState.returnTo;
    } catch (err) {
      path = undefined;
    }

    let path;
    if (slug) {
      path = `/oidc/${encodeURIComponent(slug)}/login`;
    } else {
      path = "/login";
    }
    if (returnTo) {
      path += `?returnTo=${encodeURIComponent(returnTo)}`;
    }
    return path;
  }, [state]);

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <LoginBoxComponent title="Log In">
          <Center>
            {errorMessage ? (
              <ErrorComponent title={errorMessage}>
                Go to the{" "}
                <Link as={RouterLink} to={loginPath}>
                  login page
                </Link>{" "}
                to try again or go to the{" "}
                <Link as={RouterLink} to="/">
                  home page
                </Link>
                .
              </ErrorComponent>
            ) : (
              <SpinnerComponent />
            )}
          </Center>
        </LoginBoxComponent>
      </Flex>
    </ExternalContainer>
  );
}

/**
 * @param {Function} dispatch
 * @param {string} code
 * @param {string} state
 * @param {string} [errorDescription]
 * @param {string} [error]
 * @param {{ persistLogin?: boolean }} [options]
 */
async function processAuthorizationCodeAsync(dispatch, code, state, errorDescription, error, options) {
  if (errorDescription) {
    throw new OAuthError(errorDescription, error);
  }

  const { clientId, tokenEndpoint, redirectUri, codeVerifier, nonce, issuer } = getOidcSessionStorage();
  if (!clientId && !tokenEndpoint && !redirectUri && !codeVerifier) {
    throw new OAuthError("This authorization session has already been used.");
  }

  const { returnTo, slug } = parseOidcState(state);

  try {
    // Trade the authorization code for an id token
    const oidcTokenRequest = {
      grant_type: "authorization_code",
      client_id: clientId,
      code,
      code_verifier: codeVerifier,
      redirect_uri: redirectUri,
    };
    const oidcTokenFormData = new URLSearchParams();
    for (const key of Object.keys(oidcTokenRequest)) {
      const value = oidcTokenRequest[key];
      const valueString = convertToString(value);
      if (valueString) {
        oidcTokenFormData.append(key, valueString);
      }
    }
    const oidTokenResponse = await fetch(tokenEndpoint, {
      method: "POST",
      body: oidcTokenFormData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const oidTokenResponseData = await oidTokenResponse.json();
    if (!oidTokenResponse.ok || oidTokenResponseData.error) {
      let message = "Authentication failed";
      if (oidTokenResponseData?.error_description) {
        message += `: ${oidTokenResponseData.error_description}`;
      }
      throw new OAuthError(message, oidTokenResponseData.error);
    }
    if (!oidTokenResponseData.id_token) {
      throw new Error("Missing id token");
    }

    // Parse the ID Token and validate its claims.
    // Based on [3.1.3.7. ID Token Validation](https://openid.net/specs/openid-connect-core-1_0.html#IDTokenValidation):
    const idTokenPayload = decodeJwtPayload(oidTokenResponseData.id_token);

    // > The Issuer Identifier for the OpenID Provider MUST exactly match the value of the iss (issuer) Claim.
    if (issuer && stringCompareIgnoreCase(idTokenPayload.iss, issuer) !== 0) {
      throw new Error(`Issuer mismatch: expected ${issuer} but was ${idTokenPayload.iss}`);
    }

    // > The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer identified by the iss (issuer) Claim as an audience.
    if (stringCompareIgnoreCase(idTokenPayload.aud, clientId) !== 0) {
      throw new Error(`Audience mismatch: expected ${clientId} but was ${idTokenPayload.aud}`);
    }

    // > If a nonce value was sent in the Authentication Request, a nonce Claim MUST be present and its value checked to verify that it is the same value as the one that was sent in the Authentication Request.
    const actualNonce = idTokenPayload.nonce;
    if (nonce && nonce !== actualNonce) {
      throw new Error("Nonce mismatch");
    }

    try {
      await loginUserByIdToken(dispatch, slug, oidTokenResponseData.id_token, options);
    } catch (err) {
      if (OAuthError.isOAuthError(err)) {
        err.data = { id_token: oidTokenResponseData.id_token, slug };
      }
      throw err;
    }
    return { returnTo };
  } finally {
    clearOidcSessionStorage();
  }
}

/**
 * @param {string} jwt
 */
function decodeJwtPayload(jwt) {
  const payloadB64 = jwt.split(".")[1];
  const payloadJson = base64url.atob(payloadB64);
  const payload = JSON.parse(payloadJson);
  return payload;
}

/**
 * @param {string | number | boolean | undefined} value
 */
function convertToString(value) {
  if (typeof value === "undefined") {
    return undefined;
  }
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  }
  if (typeof value === "number") {
    if (isNaN(value)) {
      return undefined;
    }
    return value.toString();
  }
  return value;
}

/**
 * @param {string} a
 * @param {string} b
 * @returns { -1 | 0 | 1}
 */
function stringCompareIgnoreCase(a, b) {
  if (a === b) {
    return 0;
  }
  if (typeof a !== "string" && typeof b !== "string") {
    return 0;
  }
  if (typeof a !== "string") {
    return -1;
  }
  if (typeof b !== "string") {
    return 1;
  }

  const result = a.localeCompare(b, undefined, {
    sensitivity: "base",
    numeric: false,
  });
  if (result < 0) return -1;
  if (result > 0) return 1;
  return 0;
}

function mapStateToProps(store) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(OidcCallbackContainer));
