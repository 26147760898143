/* eslint-disable no-script-url */
import { Center, Flex, Link } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { getDepartmentOidc } from "../api/OidcApi";
import ErrorComponent from "../components/common/ErrorComponent";
import SpinnerComponent from "../components/common/SpinnerComponent";
import LoginBoxComponent from "../components/login/LoginBoxComponent";
import { OAuthError, redirectToOidcAuthorizationServer } from "../helpers/Oidc";
import { ExternalContainer } from "./ExternalContainer";

/**
 * @param {{requireOidc?: boolean}} props
 */
export default function DepartmentLoginContainer(props) {
  const { requireOidc } = props;
  const { slug } = useParams();

  const location = useLocation();
  /** @type {URLSearchParams} */
  let query;
  try {
    query = new URLSearchParams(location?.search);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Invalid or missing query string", err);
    query = new URLSearchParams();
  }
  const returnTo = query.get("returnTo");

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  React.useEffect(() => {
    getDepartmentOidc(slug)
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`Failed to get department OIDC`, err);
        return undefined;
      })
      .then((oidc) => {
        if (!oidc) {
          if (requireOidc) {
            setErrorMessage("External login is not available for this department.");
          } else {
            let loginPath = "/login";
            if (returnTo) {
              loginPath += `?returnTo=${encodeURIComponent(returnTo)}`;
            }
            navigate(loginPath, { replace: true });
          }
          return Promise.resolve();
        }

        const stateData = { slug, returnTo };
        return redirectToOidcAuthorizationServer(oidc, stateData);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`OIDC login failed`, err);

        let message;
        if (OAuthError.isOAuthError(err) && err.error !== "server_error") {
          message = err.message;
        }
        if (!message) message = "An error occurred";
        setErrorMessage(message);
      });
  }, [slug, requireOidc, returnTo, navigate]);

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <LoginBoxComponent title="Log In">
          <Center>
            {errorMessage ? (
              <ErrorComponent title={errorMessage}>
                Go to the{" "}
                <Link as={RouterLink} to={"/login"}>
                  password login page
                </Link>{" "}
                or <Link href="javascript:window.location.reload(true)">refresh the page</Link> to try again.
              </ErrorComponent>
            ) : (
              <SpinnerComponent />
            )}
          </Center>
        </LoginBoxComponent>
      </Flex>
    </ExternalContainer>
  );
}
