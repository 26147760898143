import React, { useState } from 'react';
import {
  Flex,
  Stack,
  Text,
  Box,
  Heading,
  Divider,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';
import { LinkButton, OutlineButton, SubmitButton } from '../../components/form/Button';
import { updateUserPassword, updateUserProfile } from '../../features/user/userSlice';
import FormComponent from '../../components/form/FormComponent';
import { PasswordReset } from '../../models/PasswordReset';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputWithLabel } from '../../components/form/FormWrapper';
import { FlexWithBackgroundTheme } from '../ThemeContainer';
import {UnitsCard} from '../../components/units/UnitsCard';
import { NotificationSettingsCard } from '../../components/config/NotificationSettings';
import { updateUserConfig } from '../../features/user/userSlice';

export const ProfileContainer = (props) => {
  const user = useSelector(state => state.user);
  const uiConfig = useSelector(state => state.user && state.user.uiConfig);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const onSubmitNotificationSettings = async(config) => {
    const resultAction = await dispatch(updateUserConfig(config));

    return updateUserConfig.fulfilled.match(resultAction);
  };

  return (
    <FlexWithBackgroundTheme height={'calc(100vh - 4.25rem)'} overflow="auto" w="100%" flexDirection="column" paddingBottom="1.5rem" paddingX={'1.5rem'}>
      <Box marginTop="2rem" maxWidth="32rem" minWidth="24rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
        <Flex align="center" justify="space-between">
          <Heading as="h4" size="md">My Profile</Heading>
          <LinkButton onClick={() => setIsEditing(!isEditing)}>{isEditing ? 'Cancel' : 'Edit'}</LinkButton>
        </Flex>
        <Divider my="0.5rem" />
        {!user && <Text>Loading...</Text>}
        {user && (
          isEditing ? <ProfileForm user={user} setIsEditing={setIsEditing} /> : <ProfileCard user={user} />
        )}
      </Box>
      <UnitsCard />
      <NotificationSettingsCard config={uiConfig} onSubmit={onSubmitNotificationSettings} />
    </FlexWithBackgroundTheme>
  );
};

const ProfileCard = (props) => {
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.25rem',
    color: 'gray.700'
  };
  return (
    <Box>
      <Stack spacing="0.5rem">
        <Stack spacing="0">
          <Text {...labelStyle}>Name</Text>
          <Text fontWeight="600">{props.user.firstName}&nbsp;{props.user.lastName}</Text>
        </Stack>
        <Stack spacing="0">
          <Text {...labelStyle}>Email</Text>
          <Text fontWeight="600">{props.user.email}</Text>
        </Stack>
        {props.user.oidc && (
          <Stack spacing="0">
            <Text {...labelStyle}>External Username</Text>
            <Text fontWeight="600" title={props.user.oidc.iss ? `Issued by ${props.user.oidc.iss}` : null}>{props.user.oidc.preferredUsername ?? "Yes"}</Text>
          </Stack>
        )}
        <Stack spacing="0">
          <Text {...labelStyle}>Role</Text>
          <Text fontWeight="600">{props.user.role}</Text>
        </Stack>
        <Divider />
      <PasswordResetForm />
      </Stack>
    </Box>
  );
};


const ProfileForm = (props) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const onSubmit = async(values) => {
    const data = [];
    const editableFields = ['firstName', 'lastName'];
    // @todo refactor form structure on profile
    Object.keys(values).filter(k => editableFields.includes(k)).forEach(k => data.push({
      op: 'replace',
      path: `/${k}`,
      value: values[k]
    }));

    const resultAction = await dispatch(updateUserProfile(data));

    if (updateUserProfile.fulfilled.match(resultAction)) {
      toast({
        title: 'Success',
        position: 'top',
        description: 'Profile updated',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
      props.setIsEditing(false);
    } else {
      // @todo Validation errors
      toast({
        title: 'Error',
        position: 'top',
        description: 'Failed to update profile. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };
  const userFormData = {
    firstName: props.user.firstName,
    lastName: props.user.lastName,
  };

  const formFields = [
    {
      id: 'firstName',
      name: 'firstName',
      type: 'text',
      label: 'First Name',
    },
    {
      id: 'lastName',
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
    }
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormComponent formFields={formFields} data={userFormData} />
        <SubmitButton isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
          Save
        </SubmitButton>
      </form>
    </FormProvider>
  );
};


const PasswordResetForm = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const methods = useForm({
    resolver: zodResolver(PasswordReset)
  });

  const formFields = [
    {
      id: 'currentPassword',
      name: 'currentPassword',
      type: 'password',
      label: 'Current Password',
    },
    {
      id: 'newPassword',
      name: 'newPassword',
      type: 'password',
      label: 'New Password',
    },
    {
      id: 'confirmPassword',
      name: 'confirmPassword',
      type: 'password',
      label: 'Confirm Password',
    }
  ];

  const onSubmit = async(values) => {
    const responseData = await dispatch(updateUserPassword({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    }));

    if (updateUserPassword.fulfilled.match(responseData)) {
      toast({
        title: 'Success',
        position: 'top',
        description: 'Password updated',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
      setIsEditing(false);
    } else {
      // @todo Validation errors
      toast({
        title: 'Error',
        position: 'top',
        description: 'Password update failed, please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <React.Fragment>
      {isEditing && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="0.5rem">
              {formFields.map(field => (
                <InputWithLabel size="sm" {...field} key={field.id} />
              ))}
            </Stack>
            <Flex mt="1rem" justifyContent="space-between" alignItems="center">
            <SubmitButton isLoading={methods.formState.isSubmitting} colorScheme="blue" type="submit">
              Save
            </SubmitButton>
            <LinkButton onClick={() => setIsEditing(false)}>Cancel</LinkButton>
            </Flex>
          </form>
        </FormProvider>
      )}
      {!isEditing && <OutlineButton onClick={() => setIsEditing(!isEditing)}>Update Password</OutlineButton>}
    </React.Fragment>
  );
};
