/* eslint-disable no-script-url */
import { Box, Flex, Link, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import * as z from "zod";
import { submitOidcLinkUser } from "../api/OidcApi";
import ErrorComponent from "../components/common/ErrorComponent";
import { SubmitButton } from "../components/form/Button";
import FormComponent from "../components/form/FormComponent";
import SignUpSecondaryBoxComponent from "../components/SignUpSecondary/SignUpSecondaryBoxComponent";
import { ExternalContainer } from "./ExternalContainer";

export default function OidcLinkUserContainer() {
  const { slug } = useParams();

  const location = useLocation();
  /** @type {URLSearchParams} */
  let query;
  try {
    query = new URLSearchParams(location?.search);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Invalid or missing query string", err);
    query = new URLSearchParams();
  }
  const idToken = query.get("id_token");
  const email = query.get("email");

  const toast = useToast();
  const navigate = useNavigate();
  const form = useForm({
    resolver: zodResolver(
      z.object({
        password: z.string().min(1, "A password is required"),
      })
    ),
  });

  const onSubmit = (values) => {
    return new Promise((resolve) =>
      submitOidcLinkUser(
        {
          idToken,
          email,
          password: values.password,
        },
        slug,
        () => {
          toast({
            title: "Success",
            position: "top",
            description: "User registration complete.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate(`/oidc/${encodeURIComponent(slug)}/login`);
          resolve();
        },
        () => {
          toast({
            title: "Error",
            position: "top",
            description: "There was an error registering your account, please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          resolve();
        }
      )
    );
  };

  const formFields = [
    {
      id: "email",
      name: "email",
      type: "static",
      label: "Email",
    },
    {
      id: "password",
      name: "password",
      type: "password",
      label: "Password",
      required: true,
    },
  ];
  const formData = { email };

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <SignUpSecondaryBoxComponent title="Complete User Registration">
          <Box marginBottom="1rem">
            <p>Sign in to link your existing account.</p>
          </Box>
          {idToken && slug ? (
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormComponent formFields={formFields} data={formData} />
                <SubmitButton width="100%" isLoading={form.formState.isSubmitting} mt={4} type="submit">
                  Submit
                </SubmitButton>
              </form>
            </FormProvider>
          ) : (
            <ErrorComponent>
              Registration link is invalid. Go to the{" "}
              <Link as={RouterLink} to={slug ? `/oidc/${encodeURIComponent(slug)}/login` : "/login"}>
                login page
              </Link>{" "}
              or <Link href="javascript:history.back()">go back</Link> to try again.
            </ErrorComponent>
          )}
        </SignUpSecondaryBoxComponent>
      </Flex>
    </ExternalContainer>
  );
}
