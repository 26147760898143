/**
 * @param {{ issuer?: string; clientId?: string; redirectUri?: string; authorizationEndpoint?: string;}} oidc
 * @param {{returnTo?: string; slug?: string}} stateData
 */
export async function redirectToOidcAuthorizationServer(oidc, stateData) {
  const redirectUri = oidc.redirectUri ?? generateDefaultRedirectUri();
  const state = stringifyOidcState(stateData);
  const nonce = generateNonce();
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  const url = new URL(oidc.authorizationEndpoint);
  url.searchParams.append("client_id", oidc.clientId);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("redirect_uri", redirectUri);
  url.searchParams.append("scope", "openid");
  url.searchParams.append("state", state);
  url.searchParams.append("nonce", nonce);
  url.searchParams.append("code_challenge_method", "S256");
  url.searchParams.append("code_challenge", codeChallenge);

  sessionStorage.setItem("oidc-client-id", oidc.clientId);
  sessionStorage.setItem("oidc-token-endpoint", oidc.tokenEndpoint);
  sessionStorage.setItem("oidc-issuer", oidc.issuer);
  sessionStorage.setItem("oidc-redirect-uri", redirectUri);
  sessionStorage.setItem("oidc-code-verifier", codeVerifier);
  sessionStorage.setItem("oidc-nonce", nonce);
  window.location.href = url.href;
}

export function getOidcSessionStorage() {
  const clientId = sessionStorage.getItem("oidc-client-id") ?? undefined;
  const tokenEndpoint = sessionStorage.getItem("oidc-token-endpoint") ?? undefined;
  const issuer = sessionStorage.getItem("oidc-issuer") ?? undefined;
  const redirectUri = sessionStorage.getItem("oidc-redirect-uri") ?? undefined;
  const codeVerifier = sessionStorage.getItem("oidc-code-verifier") ?? undefined;
  const nonce = sessionStorage.getItem("oidc-nonce") ?? undefined;
  return { clientId, tokenEndpoint, issuer, redirectUri, codeVerifier, nonce };
}

export function clearOidcSessionStorage() {
  sessionStorage.removeItem("oidc-client-id");
  sessionStorage.removeItem("oidc-token-endpoint");
  sessionStorage.removeItem("oidc-issuer");
  sessionStorage.removeItem("oidc-redirect-uri");
  sessionStorage.removeItem("oidc-code-verifier");
  sessionStorage.removeItem("oidc-nonce");
}

/**
 * @param {{ returnTo?: string; slug?: string }} stateData
 */
function stringifyOidcState(stateData) {
  return base64url.btoa(JSON.stringify(stateData));
}

/**
 * @param {string} state
 * @returns {{ returnTo?: string; slug?: string }}
 */
export function parseOidcState(state) {
  if (!state) {
    return {};
  }
  return JSON.parse(base64url.atob(state));
}

function generateNonce() {
  return generateRandomB64Encoded(16);
}

function generateCodeVerifier() {
  return generateRandomB64Encoded(20);
}

/**
 * @param {number} len
 */
function generateRandomB64Encoded(len) {
  const bytes = crypto.getRandomValues(new Uint8Array(len));
  return base64url.btoa(bytes);
}

/**
 * @param {string} codeVerifier
 */
async function generateCodeChallenge(codeVerifier) {
  const hashBytes = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(codeVerifier));
  const bytes = new Uint8Array(hashBytes);
  return base64url.btoa(bytes);
}

export function generateDefaultRedirectUri() {
  const redirectUriBuilder = new URL(window.location.href);
  redirectUriBuilder.pathname = "/";
  redirectUriBuilder.pathname += `oidc-callback`;
  redirectUriBuilder.search = "";
  return redirectUriBuilder.href;
}

export const base64url = {
  /**
   * @param {string} b64
   */
  atob(b64) {
    let input = b64;
    if (typeof input === "string") {
      input = input.replace(/-/g, "+");
      input = input.replace(/_/g, "/");
      switch (input.length % 4) {
        case 2:
          input += "==";
          break;
        case 3:
          input += "=";
          break;
        default:
          break;
      }
    }
    return window.atob(input);
  },

  /**
   * @param {string|Uint8Array} data
   */
  btoa(data) {
    let input;
    if (typeof data === "string") {
      input = data;
    } else if (ArrayBuffer.isView(data)) {
      input = String.fromCharCode(...data);
    } else {
      throw new TypeError("data must be a string or Uint8Array");
    }

    const b64 = window.btoa(input);
    const b64Url = b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    return b64Url;
  },
};

export class OAuthError extends Error {
  /**
   * @param {string} message
   */
  constructor(message, error) {
    super(message);
    Object.setPrototypeOf(this, OAuthError.prototype);
    this.error = error ?? "";
  }

  /** @type {string} */
  error;

  /** @type {{ slug?: string; id_token?: string; } | undefined} */
  data;

  /**
   * @param {unknown} err
   * @returns {err is OAuthError}
   */
  static isOAuthError(err) {
    return err && typeof err === "object" && "error" in err && typeof err.error === "string";
  }
}
