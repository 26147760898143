import { Spinner } from "@chakra-ui/react";
import React from "react";

/**
 * @param {{size?: "xl" | "lg"}} props
 */
export default function SpinnerComponent(props) {
  return (
    <Spinner
      thickness=".25rem"
      speed=".5s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
      {...props}
    />
  );
}
