import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import auth from "../auth/Authenticator";
import MainContainer from "../containers/MainContainer";

/**
 * @param {{ children: React.ReactNode, role?: string|null }} param0
 */
export function RequireAuth({ children, role = null }) {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  
  if (!user || !auth.loggedIn()) {
    return <UnauthorizedContainer />;
  }

  const allowed = role && user.role ? user.role === role : true;
  if (!allowed) {
    return (
      <Navigate to={{
          pathname: "/",
          state: { from: location },
          search: location.search,
        }} replace
      />
    );
  }

  return <MainContainer>{children}</MainContainer>;
}

function UnauthorizedContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    navigate(
      {
        pathname: resolveLoginPathname(location),
        state: { from: location },
        search: resolveLoginSearch(location),
      },
      { replace: true }
    );
  }, [navigate, location]);

  return null;
}

/**
 * @param {import('react-router').Location} location
 */
function resolveLoginPathname(location) {
  let loginPath = "/login";
  try {
    const query = new URLSearchParams(location?.search);
    const loginSlug = query.get("department");
    if (loginSlug) {
      loginPath += `/${encodeURIComponent(loginSlug)}`;
    }
  } catch (err) {
    // Do nothing
  }
  return loginPath;
}

/**
 * @param {import('react-router').Location} location
 */
function resolveLoginSearch(location) {
  let returnTo = location.pathname;
  if (!returnTo || returnTo === "/" || returnTo === "/login") {
    return location.search;
  }

  let search = location.search;
  if (search) {
    returnTo += search;

    const searchParams = new URLSearchParams(search);
    searchParams.set("returnTo", returnTo);
    search = `?${searchParams.toString()}`;
  } else {
    search = `?returnTo=${encodeURIComponent(returnTo)}`;
  }
  return search;
}
