import { Flex, useToast } from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "../components/form/Button";
import FormComponent from "../components/form/FormComponent";
import LoginBoxComponent from "../components/login/LoginBoxComponent";
import { ExternalContainer } from "./ExternalContainer";

export default function OidcRootContainer() {
  const form = useForm();
  const toast = useToast();
  const navigate = useNavigate();

  const formFields = [
    {
      id: "slug",
      name: "slug",
      type: "text",
      label: "Department",
      required: true,
    },
  ];
  const formData = {};

  const onSubmit = (values) => {
    const slug = makeSlug(values?.slug);
    if (!slug) {
      toast({
        title: "Error",
        position: "top",
        description: "Invalid department.  Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    navigate(`/oidc/${slug}/login`);
  };

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <LoginBoxComponent title="Choose Department">
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormComponent formFields={formFields} data={formData} />
              <SubmitButton width="100%" isLoading={form.formState.isSubmitting} mt={4} type="submit">
                Submit
              </SubmitButton>
            </form>
          </FormProvider>
        </LoginBoxComponent>
      </Flex>
    </ExternalContainer>
  );
}

/**
 * @param {string} slugOrName
 */
function makeSlug(slugOrName) {
  if (!slugOrName || typeof slugOrName !== "string") {
    return undefined;
  }

  // If we have what looks like a name, then try the expected API logic to convert it to a slug.
  if (slugOrName.trim().includes(" ")) {
    const cleanName = slugOrName.replace(/[^A-Za-z0-9 ]/g, "");
    const stopwords = ["a", "an", "the", "to", "of"];
    const words = cleanName.split(/\s+/);
    let slug = words
      .map((word) => word.toLowerCase())
      .filter((word) => !stopwords.includes(word))
      .join("")
      .trim();
    if (slug.length > 12 && !/[0-9]$/.test(slug)) {
      slug = slug.substring(0, 12).trim();
    }
    return slug;
  }

  return slugOrName.trim().toLowerCase();
}
