/* eslint-disable no-script-url */
import { Center, Flex, Link } from "@chakra-ui/react";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import * as sessionAPI from "../api/SessionAPI";
import ErrorComponent from "../components/common/ErrorComponent";
import SpinnerComponent from "../components/common/SpinnerComponent";
import LoginBoxComponent from "../components/login/LoginBoxComponent";
import withNavigation from "../withNavigation";
import { ExternalContainer } from "./ExternalContainer";

function AutoLoginContainer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  React.useEffect(() => {
    /** @type {URLSearchParams} */
    let query;
    try {
      query = new URLSearchParams(props.location?.search);
    } catch (err) {
      setErrorMessage("Missing parameters");
      return;
    }
    const accessToken = query.get("access_token");
    const email = query.get("email");
    const password = query.get("password");

    /** @type {Promise<void>} */
    let promise;
    if (accessToken) {
      promise = new Promise((resolve, reject) => {
        sessionAPI.loginUserByAccessToken(dispatch, accessToken, resolve, reject);
      });
    } else if (email && password) {
      promise = new Promise((resolve, reject) => {
        const credentials = {
          email,
          password,
          persistLogin: false,
        };
        sessionAPI.loginUser(dispatch, credentials, resolve, reject);
      });
    } else {
      promise = Promise.reject(new Error("Missing credentials"));
    }
    promise
      .then(() => navigate("/", { replace: true }))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Auto-login failed", err);
        setErrorMessage("Auto-login failed");
      });
  }, [props.location.search, dispatch, navigate]);

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <LoginBoxComponent title="Auto Log In">
          <Center>
            {errorMessage ? (
              <ErrorComponent title={errorMessage}>
                Go to the{" "}
                <Link as={RouterLink} to={"/login"}>
                  login page
                </Link>{" "}
                or <Link href="javascript:window.location.reload(true)">refresh the page</Link> to try again.
              </ErrorComponent>
            ) : (
              <SpinnerComponent />
            )}
          </Center>
        </LoginBoxComponent>
      </Flex>
    </ExternalContainer>
  );
}

function mapStateToProps(store) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(AutoLoginContainer));
