import { Flex, Link, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import * as z from "zod";
import { submitOidcSignUp } from "../api/OidcApi";
import ErrorComponent from "../components/common/ErrorComponent";
import { SubmitButton } from "../components/form/Button";
import FormComponent from "../components/form/FormComponent";
import SignUpSecondaryBoxComponent from "../components/SignUpSecondary/SignUpSecondaryBoxComponent";
import { OAuthError } from "../helpers/Oidc";
import { ExternalContainer } from "./ExternalContainer";

export default function OidcSignUpContainer() {
  const { slug } = useParams();

  const location = useLocation();
  /** @type {URLSearchParams} */
  let query;
  try {
    query = new URLSearchParams(location?.search);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Invalid or missing query string", err);
    query = new URLSearchParams();
  }
  const idToken = query.get("id_token");

  const toast = useToast();
  const navigate = useNavigate();
  const form = useForm({
    resolver: zodResolver(
      z.object({
        email: z.string().min(1, "A valid email is required"),
        firstName: z.string().min(1, "A first name is required"),
        lastName: z.string().min(1, "A last name is required"),
      })
    ),
  });

  const onSubmit = (values) => {
    return new Promise((resolve) =>
      submitOidcSignUp(
        {
          idToken,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        },
        slug,
        () => {
          toast({
            title: "Success",
            position: "top",
            description: "User registration complete.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate(`/oidc/${encodeURIComponent(slug)}/login`);
          resolve();
        },
        (err) => {
          if (OAuthError.isOAuthError(err) && err.error === "email_conflict") {
            navigate(`/oidc/${encodeURIComponent(slug)}/linkUserForm?id_token=${encodeURIComponent(idToken)}&email=${encodeURIComponent(values.email)}`);
            resolve();
            return;
          }

          toast({
            title: "Error",
            position: "top",
            description: "There was an error registering your account, please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          resolve();
        }
      )
    );
  };

  const formFields = [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "Email",
      required: true,
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "First Name",
      required: true,
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "Last Name",
      required: true,
    },
  ];
  const formData = {};

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <SignUpSecondaryBoxComponent title="Complete User Registration">
          {idToken && slug ? (
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormComponent formFields={formFields} data={formData} />
                <SubmitButton width="100%" isLoading={form.formState.isSubmitting} mt={4} type="submit">
                  Submit
                </SubmitButton>
              </form>
            </FormProvider>
          ) : (
            <ErrorComponent>
              Registration link is invalid. Go to the{" "}
              <Link as={RouterLink} to={slug ? `/oidc/${encodeURIComponent(slug)}/login` : "/login"}>
                login page
              </Link>{" "}
              to try again.
            </ErrorComponent>
          )}
        </SignUpSecondaryBoxComponent>
      </Flex>
    </ExternalContainer>
  );
}
