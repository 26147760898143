import React from "react";

import { Box, Divider, Heading, Stack, useColorModeValue } from "@chakra-ui/react";
import FlowLogo from "../../svg/FlowMSPImageTrendLogo";

/**
 * @param {{ title: string; children: React.ReactNode }} props
 */
export default function SignUpSecondaryBoxComponent(props) {
  const { title, children } = props;
  return (
    <Box rounded={"xl"} bg={useColorModeValue("white", "gray.700")} boxShadow={"xl"} minWidth="24rem">
      <Stack spacing="1rem" mx={"auto"} maxW={"xl"} py={6} px={6}>
        <Box align="center">
          <FlowLogo />
        </Box>
        <Divider />
        <Heading as="h4" size="md">
          {title}
        </Heading>
        <Box bg={useColorModeValue("white", "gray.700")}>{children}</Box>
      </Stack>
    </Box>
  );
}
